<template>
  <div>
    <label class="input-container">
      <span> {{ label }} </span>
      <input
        v-model="theModel"
        type="checkbox"
      >
      <span class="slider" />
    </label>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';

export default {
  props: {
    id: String,
    var: Object,
    label: String,
  },
  computed: {
    theModel: {
      get () {
        return this.var[this.id];
      },
      set (val) {
        this.var[this.id] = val;

        if (!this.var.touched) {
          this.var.touched = true;
          EventBus.$emit('settings-changed');
        }
      }
    }
  },
  mounted () {
    if (this.var[this.id] === undefined) {
      this.var[this.id] = true;
    }
  }
}
</script>

<style scoped>

.input-container {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
}

.input-container > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #43b649;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
</style>
