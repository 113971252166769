<template>
  <div v-if="isMounted" class="settings-page-wrapper">
    <!-- back button -->
    <div style="margin-top:20px">
      <button
        class="lightest-grey-button min-width-button sharp-corner-button"
        style="color: #000"
        @click="$router.push({ name: 'application-page' })"
      >
        <span class="back-button"> <img src="~/assets/img/back_button-black.svg"> {{ getGeneralTexts('backToAppsBtn') }} </span>
      </button>
    </div>

    <!-- settings panel -->
    <div v-if="user">
      <h1 style="color: #39393F;"> {{ getGeneralTexts('header_label') }} </h1>
      <div class="settings-container">
        <div class="settings-panel">
          <h3>{{ getSectionHeader('emailNotifications') }}</h3>
          <div v-for="setting in settings.emailNotifications" :key="setting" class="settings-field">
            <div class="left-side">
              <div class="settings-label"> {{ getFieldTexts(setting).label }} </div>
              <span class="settings-description">{{ getFieldTexts(setting).description }}</span>
            </div>
            <div class="right-side">
              <toggle-field :id="setting" :var="userSettings" />
            </div>
          </div>
        </div>
        <div class="settings-panel">
          <h3>{{ getSectionHeader('productSettings') }}</h3>
          <div class="settings-field">
            <div class="left-side">
              <span class="settings-description">{{ getFieldTexts('productSettingsFor').label }}</span>
            </div>
            <div class="right-side">
              <select v-model="currentProduct" class="full-width">
                <option v-for="product in products" :key="product.value" :value="product.value">
                  {{ getProductText(product.value) }}
                </option>
              </select>
            </div>
          </div>
          <div class="settings-field">
            <div class="left-side">
              <div class="settings-label">{{ getFieldTexts('defaultLoanFee').label }}</div>
              <span class="settings-description">{{ getFieldTexts('defaultLoanFee').description }}</span>
            </div>
            <div class="right-side">
              <toggle-field id="defaultLoanFeeEnabled" :var="currentProductSettings" />
            </div>
          </div>
          <template v-if="currentProductSettings.defaultLoanFeeEnabled">
            <div style="background: white; padding: 10px;">
              <input type="number" style="width: 70px; margin-right: 5px" :value="loanPercentage" @blur="handleLoanPercentChange">
              {{ getFieldTexts('loanFeePercentHelp').label }}
            </div>
          </template>
        </div>
      </div>
      <button v-if="userSettings.touched || currentProductSettings.touched" class="green-button sharp-corner-button" style="max-width:1200px" @click="updateSettings()">Update settings</button>

      <transition name="fade">
        <div v-if="toaster.show" class="toaster"> {{ toaster.msg }} </div>
      </transition>
    </div>

    <!-- user not found page -->
    <div v-else-if="!user">
      User not found
    </div>
  </div>
</template>

<script>
import EventBus from '../../js/eventBus';
import toggleField from '../../components/inputFields/toggle';
import auth from '../../mixins/apiServices/auth';
import productsMixin from '../../mixins/apiServices/products'
import { productSettings } from '../../mixins/localeConfig'

import { useAppStore } from '../../store/app';
import { useAuthStore } from '../../store/auth';
import { useDomainStore } from '../../store/domain';

export default {
  components: {
    'toggle-field': toggleField,
  },
  mixins: [auth, productsMixin],
  data () {
    return {
      user: undefined,
      userSettings: {},
      settings: {
        emailNotifications: ['partnerChosenOffer', 'partnerNewApp', 'partnerNewRevision', 'partnerAppointment']
      },
      currentProductSettings: {},
      toaster: {
        show: false,
        msg: ''
      },
      isMounted: false,
      appStore: useAppStore(),
      authStore: useAuthStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    settingTexts () {
      return this.domainStore.settingTexts();
    },
    productTexts () {
      return this.domainStore.productTexts();
    },
    products () {
      return this.domainStore.products();
    },
    currentProduct () {
      return this.appStore.currentProduct;
    },
    loanPercentage () {
      return this.currentProductSettings.defaultLoanFeePercentage.toFixed(2);
    }
  },
  watch: {
    currentProduct: {
      handler (value) {
        const product = this.products.find(product => value === product.value)
        this.currentProductSettings = { ...(product?.settings ?? { defaultLoanFeePercentage: 0.01, defaultLoanFeeEnabled: false }) };
      },
      deep: true,
      immediate: true,
    },
    'currentProductSettings.defaultLoanFeeEnabled': {
      handler () {
        if (this.currentProductSettings.defaultLoanFeePercentage === undefined) {
          this.currentProductSettings.defaultLoanFeePercentage = 0.01;
        }
      }
    }
  },
  async mounted () {
    const { responseCode, data } = await this.getUser(this.authStore.token);
    if (responseCode === 200) {
      this.user = { ...data, userId: data._id };
      if (this.user.settings) {
        const { emailNotifications } = this.user.settings;
        this.userSettings = (emailNotifications) || {};
      } else this.userSettings = {};
    }

    this.userSettings.touched = false;
    this.currentProductSettings.touched = false;

    EventBus.$on('settings-changed', this.forceUpdate)

    this.isMounted = true;
  },
  unmounted () {
    EventBus.$off('settings-changed', this.forceUpdate);
  },
  methods: {
    async updateSettings () {
      const postObject = {};
      Object.keys(this.settings).forEach((setting) => {
        postObject[setting] = {};
        this.settings[setting].forEach((item) => {
          postObject[setting][item] = this.userSettings[item];
        });
      });

      const { responseCode: userResCode, data: userData } = await this.updateUserSetting({ settings: postObject }, this.user._id);

      await this.updateProductSettings(this.currentProduct, this.currentProductSettings)

      if (userResCode === 200) this.toaster.msg = 'Settings updated';
      else this.toaster.msg = userData.message;

      const { responseCode: productResCode, data: productData } = await this.updateProductSettings(this.currentProduct, this.currentProductSettings)

      if (productResCode === 200) this.toaster.msg = 'Settings updated';
      else this.toaster.msg = productData.message;

      this.userSettings.touched = false;
      this.currentProductSettings.touched = false;

      this.$forceUpdate();
      this.toaster.show = true;

      setTimeout(() => {
        this.toaster.show = false;
        if (productResCode === 200 && userResCode === 200)
          window.location.href = '/';
      }, 2500);
    },
    getGeneralTexts (index) {
      if (this.settingTexts.general[index]) return this.settingTexts.general[index].label;
      return index;
    },
    getSectionHeader (index) {
      if (this.settingTexts.sectionLabels[index]) return this.settingTexts.sectionLabels[index].label;
      return index;
    },
    getFieldTexts (index) {
      return this.settingTexts.fieldTexts[index] || index;
    },
    getProductText (key) {
      return this.productTexts[key] ?? key;
    },
    handleLoanPercentChange (event) {
      const value = event.target.value;
      this.currentProductSettings.defaultLoanFeePercentage = Number(productSettings[this.region].defaultLoanFeePercentage.handle(value));
      this.currentProductSettings.touched = true;
      this.$forceUpdate();
    },
    forceUpdate() {
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>

.settings-page-wrapper {
  background-color: #fafafa;
  padding: 15px 20px;
}

.settings-page-wrapper .back-button {
  display: flex;
  align-items: center;
}

.settings-page-wrapper .back-button > img {
  height: 10px;
  margin-right: 8px;
}

.settings-page-wrapper h1 {
  margin-top: 30px;
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 20px 0;
}

.settings-panel {
  width: 100%;
  max-width: 1200px;
}

.settings-panel > h3 {
  background-color: #EBEAEA;
  color: #39393F;
  padding: 10px;
}

.settings-field {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px 10px;
}

.settings-panel > div {
  border-bottom: 1px solid #D8D6E8;
}

.settings-field .settings-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.settings-field > .right-side {
  padding: 0px 20px;
}

.settings-description {
  color: #686868;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
