import { useAuthStore } from '../../store/auth';
import apiBaseService from './apiBaseService';
import authMixin from './auth'

export default {
  mixins: [apiBaseService, authMixin],

  data () {
    return {
      authStore: useAuthStore(),
    }
  },

  computed: {
    token () {
      return (this.authStore.token) ? this.authStore.token : '';
    },
  },

  methods: {
    async updateProductSettings (productName, productSettings) {
      let partnerToken = await this.getPartnerApiToken();

      const response = await this.partnerApiRequest({
        method: 'POST',
        headers: { authorization: `Bearer ${partnerToken}` },
        url: `/products/${productName}/update-product-settings`,
        data: productSettings,
      });

      if ([200, 409].includes(response.responseCode)) {
        return response;
      }

      partnerToken = await this.retryLoginToPartnerApi();

      return await this.partnerApiRequest({
        method: 'POST',
        headers: { authorization: `Bearer ${partnerToken}` },
        url: `/products/${productName}/update-product-settings`,
        data: productSettings,
      });
    }
  }
}
